function servicePrice(price) {
  if (price === "0.00") {
    // Change the class of #service_price to bbl-price-notice
    document.querySelector('#service_price').classList.add('bbl-price-notice');
    return "Contact Property Management";
  }
  // Remove the bbl-price-notice class of #service_price
  document.querySelector('#service_price').classList.remove('bbl-price-notice');
  return "$ " + price;
}

export async function displayBroadbandLabel(selectedService, availableServices, eventName) {
  if (eventName === 'click')
    selectedService.addEventListener('click', (e) => {
      var broadbandLabel = document.querySelector('#broadband-label');

      if (e.target.tagName === 'INPUT') {
        var serviceId = e.target.value;
        var service = availableServices.find(s => s.id == serviceId);
        if (broadbandLabel.hidden) {
          broadbandLabel.hidden = false;
        }
        document.querySelector('#service_name').textContent = service.name;
        document.querySelector('#service_price').textContent = servicePrice(service.price);
        document.querySelectorAll('#service_speed').forEach(el => el.textContent = service.max_bandwidth + ' Mbps');
        document.querySelector('#service_id').textContent = service.fcc_id;
      }
    });
  else if (eventName === 'change')
    selectedService.addEventListener('change', (e) => {
      var broadbandLabel = document.querySelector('#broadband-label');

      var serviceId = e.target.value;
      if (serviceId) {
        var service = availableServices.find(s => s.id == serviceId);
        if (broadbandLabel.hidden) {
          broadbandLabel.hidden = false;
        }
        document.querySelector('#service_name').textContent = service.name;
        document.querySelector('#service_price').textContent = servicePrice(service.price);
        document.querySelectorAll('#service_speed').forEach(el => el.textContent = service.max_bandwidth + ' Mbps');
        document.querySelector('#service_id').textContent = service.fcc_id;
      } else {
        broadbandLabel.hidden = true;
      }
    });
}

export async function initBroadbandLabel(availableServices) {
  function getServiceById(id) {
    return availableServices.find(s => s.id == id);
  }

  function updateBroadbandLabel(serviceId) {
    var service = getServiceById(serviceId);
    var broadbandLabel = document.querySelector('#broadband-label');
    if (service) {
      if (broadbandLabel.hidden) {
        broadbandLabel.hidden = false;
      }
      document.querySelector('#service_name').textContent = service.name;
      document.querySelector('#service_price').textContent = servicePrice(service.price);
      document.querySelectorAll('#service_speed').forEach(el => el.textContent = service.max_bandwidth + ' Mbps');
      document.querySelector('#service_id').textContent = service.fcc_id;
    } else {
      broadbandLabel.hidden = true;
    }
  }

  var selectedService = document.querySelector('#id_selected_service');
  updateBroadbandLabel(selectedService.value);
  selectedService.addEventListener('change', (e) => {
    updateBroadbandLabel(e.target.value);
  });
};
